// import React, { useState } from 'react';
// import Navbar from './components/Navbar/Navbar';
// import ConsultationForm from './components/ConsultationForm/ConsultationForm';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Home from './components/Home/Home';

// const App = () => {
//   const [showModal, setShowModal] = useState(false);

//   const handleShowModal = () => setShowModal(true);
//   const handleCloseModal = () => setShowModal(false);

//   return (
//     <>
//       <Navbar onConsultationClick={handleShowModal} />
//       <ConsultationForm show={showModal} handleClose={handleCloseModal} />
//       <Home onConsultationClick={handleShowModal} />

//     </>
//   );
// };

// export default App;


// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Auth from './mozimo/helpers/auth/Auth';
import Navbar from './components/Navbar/Navbar';
import ConsultationForm from './components/ConsultationForm/ConsultationForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home/Home';
import Thanks from './components/Home/thanks';


const App = () => {
    const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <>
    <Router>
      <div className="min-vh-100 d-flex flex-column">
        <Navbar handleCloseModal={handleCloseModal} showModal={handleShowModal} onConsultationClick={showModal}  />
      
        <div className="flex-grow-1">
          <Routes>
            <Route path="/" element={
      <Home handleCloseModal={handleCloseModal} onConsultationClick={handleShowModal} />
              
               } />
            <Route path="/thanks" element={<Thanks />} />
          </Routes>
      
        </div>
      </div>
    </Router>
    {/* <Navbar onConsultationClick={handleShowModal} /> */}

    

    </>
  );
}

export default App;
