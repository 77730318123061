import React from 'react'
import {
    thanks
} from '../../assets/img';

const Thanks = () => {
    return (
        <>
            <div className='text-center my-4'>
                <img src={thanks} className='' style={{ width: '200px' }} />
                <h1 style={{ color: '#98C647', fontSize: '60px' }}>Thank you for your submission!</h1>
                <p className='fs-4'>We appreciate your interest. One of our team members will contact you shortly.</p>
            </div>

        </>
    )
}

export default Thanks